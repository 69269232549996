.connect-button {
    width: fit-content;
    height: 3em;
    border-width: .1vw;
}

.connect-button-disconnected {
    background: #3f5139;
}

.connect-button-text {
    background-color: transparent;
    font-size: 16px;
    font-family: "Arial Black";
}

* {
  box-sizing: border-box;
  background-color: black;
}
a {
  text-decoration: none;
}

.font-height {
  line-height: 2;
}

h1 {
  margin-bottom: unset;
}

h2 {
  font-size: 26px;
  margin-bottom: unset;
}

button:disabled {
  background: #3f5139;
  cursor: default;
}

a {
  color: #79ee54
}

.App {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.header-nav-container {
  min-height: 2vh;
  padding: 1vw;
  display: flex;
  font-size: x-large;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: "Arial Black";
}

.header-nav-icon {
  align-self: center;
}

.main-section-container {
  margin-bottom: 2vh;
}

.main-section-container .main-section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20em;
  margin-top: 20em;
  font-family: "Arial Black";
}

.main-section-container .main-section-header-super {
  font-size: 92px;
}
.main-section-container .main-section-header-sub {
  font-size: 24px; 
}

.main-section-summary {
  display: flex;
}
.main-section-summary-image {
  min-width: 400px;
  width: 400px;
}
.main-section-summary-information {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

.main-section-summary-information .main-section-summary-header {
  font-weight: bold;
  margin-top: -16px;
}

.main-section-summary-information .main-section-summary-body {
  font-size: 16px;
}

.main-section-summary-information .main-section-summary-mint {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.main-section-summary-mint .summary-mint-details-list {
  list-style-type: none;
  padding: unset;
}

.main-section-summary-mint .terms-notice {
  margin-top: 0px;
}

.main-section-summary-mint .summary-mint-buttons {
  margin-top: 4px;
  display: flex;
  justify-content: flex-start;
}

.main-section-summary-mint .summary-mint-buttons > button {
  margin: 2px;
}

.main-section-about-apestarter {
  margin-top: 75;

}

.roadmap-list {
  text-align: left;
}

footer {
  display: flex;
  justify-content: space-between;
}

footer .badge-link {
  margin: 2px;
}

.period-spacing {
  word-spacing: 4px;
}

.mint-button {
    width: 12em;
    height: 4em;
    border-width: 1px;
}

.mint-button .mint-button-text {
    background-color: transparent;
    font-size: 24px;
    font-family: "Arial Black";
}
